import React, { Component } from 'react';
import { SceneLoader } from '../../SceneLoader';
import { SceneObjects } from './scene-objects';
import { 
    // Frame, 
    GetSDK, 
    initComponents, 
    // SceneComponent, 
    ISceneNode, 
    // OrientedBox,  
    sdkKey } from '@mp/common';

import { Button, Modal } from 'react-bootstrap';
import { BiVolumeFull } from 'react-icons/bi';
import IframeLy from '../../helper/IframeLy';

interface ModelViewerProps {
    alt?: string;
    src?: string;
    ar?: boolean;
    ['environment-image']?: string;
    poster?: string;
    ['shadow-intensity']?: string; 
    ['camera-controls']?: boolean;
    ['touch-action']?: string;
    ['camera-orbit']?: string;
    children?: any[];
}
declare global {
    namespace JSX {
      interface IntrinsicElements {
        ['model-viewer']: ModelViewerProps;
      }
    }
  }

interface Props {

}

interface State {
    showModal: boolean;
    showMessage: boolean;
    ticketTagLabel: string;
}

// augment window with the MP_SDK property
declare global {
  interface Window {
      MP_SDK: any;
  }
}


export class GoldenTicket extends Component<Props, State> {
    private src: string;
    private applicationKey: string;
    private sceneId: string = 'k6LcogVZm4d';
    private scene: SceneLoader = null;
    private sdk: any = null;
    private tagsArray: [{ tagId: string, label: string }] = null;
    private goldenTicketModel: any;

    constructor(props: Props, state: State) {
        super(props);
        
        this.state = {
            showModal: false,
            showMessage: false,
            ticketTagLabel: 'Get your Ticket!'
        };

         // Forward url params.
        const params = objectFromQuery();
        params.m = params.m || this.sceneId;
        params.play = params.play || '1'; // Play enabled
        params.title = params.title || '1'; // Show title info [Frame.tsx]
        params.help = params.help || '1'; // Present default help [Frame.tsx]
        params.brand = params.brand || '1'; // Display all branding information in the "About" panel (top-left corner of 3D Showcase) [Frame.tsx]
        params.mls = params.mls || '0'; // Show branding information, links in Mattertag� Posts, and VR (default)
        params.mt = params.mt || '1'; // Show Mattertag� Posts (default) 
        params.hr = params.hr || '1'; // Show highlight reel (default) [Frame.tsx]
        params.f = params.f || '1'; // Let the user navigate the 3D model floor by floor (default) [Frame.tsx]
        params.dh = params.dh || '1'; // Show Dollhouse View (default)
        params.qs = params.qs || '1'; // Enable Quickstart, only works if Start Position is Inside View. [Frame.tsx]
        // params.sr = params.sr || '-1.56, 1.55'; // Set camera position in photo scan
        // params.ss = params.ss || '2'; // -> Set position scan
        params.newtags = '1';

        // ensure applicationKey is inserted into the bundle query string
        params.applicationKey = params.applicationKey || sdkKey;
        this.applicationKey = params.applicationKey;

        const queryString = Object.keys(params).map((key) => key + '=' + params[key]).join('&');
        this.src = `./bundle/showcase.html?${queryString}`;
    }

    async componentDidMount() {
        const showcase = document.getElementById('showcase') as HTMLIFrameElement;

        document.title = "Digital World Creation Day";
        this.sdk = await GetSDK(showcase, this.applicationKey);
        await initComponents(this.sdk);

        // await this.sdk.App.state.waitUntil((appState: any) => appState.phase == 'appphase.playing');

        await this.sdk.Scene.configure((renderer: any, three: any) => {
            renderer.physicallyCorrectLights = true;
            renderer.gammaFactor = 2.2;
            renderer.gammaOutput = true;
            renderer.shadowMap.enabled = true;
            renderer.shadowMap.bias = 0.0001;
            renderer.shadowMap.type = three.PCFSoftShadowMap;
        });

        this.scene = new SceneLoader(this.sdk, this.sceneId, SceneObjects);
 
        await this.scene.load(this.sceneId);

        console.log('%c Bundle SDK fully loaded! ', 'background: #ffffff; color: #00dd00');
        console.log(this.sdk);

        const mpSdk = this.sdk;
        
        // replace Embedly to IframeLy
        const iframe = new IframeLy(mpSdk);
        iframe.replace();

        // add default lights
        const nodeDefaultLights = await this.sdk.Scene.createNode();
        nodeDefaultLights.addComponent('mp.lights');
        nodeDefaultLights.start();

      // GLTF loader
      this.goldenTicketModel = await this.sdk.Scene.createNode();
      const goldenTicketModelParams = {
          url: '/assets/models/GoldenTicket.glb',
          visible: true,
          localScale: { x: 1.5, y: 1.5, z: 1.5 },
          localPosition: { x: 0, y: 0, z: 0 },
          localRotation: { x: 90, y: 280, z: 0 },
          colliderEnabled: true
      };
      this.goldenTicketModel.addComponent('mp.gltfLoader', goldenTicketModelParams);
      this.goldenTicketModel.obj3D.position.set(-30, 1, -7); 
      this.goldenTicketModel.start();
      console.log('%c GLTF - Ticket loaded! ', 'background: #ffffff; color: #00dd00');


        // OBJ loader
        // const goldenTicketModel = await this.sdk.Scene.createNode();
            
        // const goldenTicketModelParams = {
        //     url: '/assets/models/GoldenTicket.obj',
        //     materialUrl: '/assets/models/GoldenTicket.mtl',
        //     visible: true,
        //     localScale: { x: 1, y: 1, z: 1 },
        //     localPosition: { x: 0, y: 0, z: 0 },
        //     localRotation: { x: 90, y: 280, z: 0 },
        //     colliderEnabled: true
        // };
        // const goldenTicketComponent = goldenTicketModel.addComponent('mp.objLoader', goldenTicketModelParams);
        // goldenTicketModel.obj3D.position.set(-30, 0, -7);
        // goldenTicketModel.start();
        // console.log('%c OBJ - Model loaded! ', 'background: #ffffff; color: #00dd00');

        const maxAnimationIterations = 50;
        let animationTarget = maxAnimationIterations;
        let animationCount = 0;
        const myself = this;
        const tick = function () {
            requestAnimationFrame(tick);
            // goldenTicketModel.obj3D.rotation.y +=0.01;
            if (myself.goldenTicketModel) {
                if (animationCount < animationTarget) {
                    myself.goldenTicketModel.obj3D.position.y += 0.001; // You decide on the increment, higher value will mean the objects moves faster
                    animationCount++;
                } 
    
                if (animationCount > animationTarget) {
                    myself.goldenTicketModel.obj3D.position.y -= 0.001; // You decide on the increment, higher value will mean the objects moves faster
                    animationCount--;
                }
    
                if (animationCount == animationTarget) {
                    if (animationCount == maxAnimationIterations) {
                        animationTarget = 0;
                    } else {
                        animationTarget = maxAnimationIterations;
                    }
                }
            }
            
        }
        tick();
        

        // -30, 1, -7

        mpSdk.Tag.add(
            {
                label: myself.state.ticketTagLabel,
                description: 'This tag was added through the Matterport SDK',
                anchorPosition: {
                    x: -30,
                    y: 1,
                    z: -7,
                },
                stemVector: { // make the Tag stick straight up and make it 0.30 meters (~1 foot) tall
                    x: 0,
                    y: 0.40,
                    z: 0,
                },
                color: { // blue disc
                    r: 0.0,
                    g: 1.0,
                    b: 0.7254901960784313,
                },
            }
        );

        this.sdk.Tag.data.subscribe({
            onAdded: function (index: string, item: any) {
                console.log('[TAG ADDED]: ', item, myself.state.ticketTagLabel)
                if (!myself.tagsArray) {
                    myself.tagsArray = [{ tagId: index, label: item.label }];
                } else {
                    myself.tagsArray.push({ tagId: index, label: item.label });
                }
                const noNavigationTag = index;
                switch (item.label) {
                    case myself.state.ticketTagLabel:
                        myself.sdk.Tag.allowAction(noNavigationTag, {
                            // implies navigating: false
                        });
                        break;
                    default:
                        break;
                }
            }
        });

        this.sdk.on(this.sdk.Mattertag.Event.CLICK,
            function (tagSid: string) {
                const tag = myself.tagsArray.find((item) => item.tagId == tagSid);
                if (tag) {
                    switch (tag.label) {
                        case myself.state.ticketTagLabel:
                            // myself.setState({ showMessage: false });
                            myself.handleShowModal();
                    }
                }
            }
        );


        const sensor = await mpSdk.Sensor.createSensor(mpSdk.Sensor.SensorType.CAMERA);
        // sensor.showDebug(true);
        const sources = await Promise.all([
            mpSdk.Sensor.createSource(mpSdk.Sensor.SourceType.BOX, {
                center: { x: -30, y: 1, z: -7 },
                // orientation: {pitch: 0, roll: 0, yaw: 0},
                size: {x: 3, y: 3, z: 5},
                userData: {
                    id: 'box-control-sensor',
                },
            }),
        ]);
        
        const nodesIterator = myself.scene.nodeIterator()
        let videoNode: ISceneNode = null;
        // let videoPlayed = false;
        for (const node of nodesIterator) {
            if (node.name === 'lobbyTV') {
                console.log('#node found', node)
                videoNode = node;
                // videoNode.obj3D.visible = false;
            }
        }
        let insideSensor = false;
        sensor.addSource(...sources);
        sensor.readings.subscribe({
            onAdded(source: any, reading: any) {
              console.log(source.userData.id, '#has a reading of:', reading);
            },
            onUpdated(source: any, reading: any) {
              console.log(source.userData.id, '#has an updated reading:', reading);
              switch(source.userData.id) {
                case 'box-control-sensor':
                  if (reading.inRange && !insideSensor) {
                        // videoNode.obj3D.visible = true;
                        // myself.playPauseVideo(videoNode, 'Play');

                        // setInterval(() => {
                        //     myself.playPauseVideo(videoNode, 'Pause');
                        //     myself.playPauseVideo(videoNode, 'Play');
                        // }, 27000);
                        insideSensor = true;
                        myself.setState({ showMessage: false });
                        // myself.handleShowModal();
                  } 
                  break;
                default:
                  break;
              }
            }
        });


        // myself.playPauseVideo(videoNode, 'Play');
        myself.playPauseVideo(videoNode, 'Pause');
        myself.playPauseVideo(videoNode, 'Play');

        setInterval(() => {
            myself.playPauseVideo(videoNode, 'Pause');
            myself.playPauseVideo(videoNode, 'Play');
        }, 40000);


        this.setState({ showMessage: true });
    }

    playPauseVideo(video: ISceneNode, status: 'Play' | 'Pause'): void {
        const componentIterator = video.componentIterator();
        for (const component of componentIterator) {
            if (component.componentType === 'mp.toggleState') {
                component.outputs.state = status === 'Play' ? true : false;
                component.outputs.negated = status === 'Play' ? false : true;
            }
            if (component.componentType === 'mp.hlsLoader') {
                component.inputs.enabled = status === 'Play' ? true : false;
            }
        }
        
    }

    handleShowModal() {
        this.setState({ showModal: true });
    }

    handleCloseModal() {
        this.setState({ showModal: false });
    }

    handleDownloadButton(session: string) {
        this.handleCloseModal();
        if (session === 'morning') {
            // download morning session
        } else {
            // download afternoon session
        }

        this.goldenTicketModel.obj3D.visible = false; 
        const myself = this;
        this.tagsArray.forEach((tag) => {
            if (tag.label === myself.state.ticketTagLabel)
                myself.sdk.Tag.remove(tag.tagId);
        })
    }

    render() {
        const { showModal, showMessage } = this.state;
        const handleCloseModal = () => this.handleCloseModal();
        const handleDownloadButton = (session: string) => this.handleDownloadButton(session);
        return (
            <div className='frame'>
                <Button id="unmuteButton" variant="dark"><BiVolumeFull /> Enable Video Sound</Button>
                <iframe id='showcase' className='frame' src={this.src}></iframe>
                {showMessage && <div className='dwc-container'>
                    <div className='dwc-content'>
                        Please get closer to grab your ticket! 
                    </div>
                </div>}
                <Modal show={showModal} onHide={handleCloseModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Get your Ticket!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        Save your invite to the DWC Day.
                        <model-viewer 
                            alt="Neil Armstrong's Spacesuit from the Smithsonian Digitization Programs Office and National Air and Space Museum" 
                            src="/assets/models/Ticket4.glb" 
                            ar={true}
                            // environment-image="shared-assets/environments/moon_1k.hdr" 
                            // poster="shared-assets/models/NeilArmstrong.webp" 
                            shadow-intensity="0" 
                            camera-controls={true}
                            camera-orbit="auto 45deg 0.5m"
                            touch-action="pan-y"
                            >
                        </model-viewer>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="dwcButton" onClick={() => handleDownloadButton('morning')}>
                            Morning Session
                        </Button>
                        <Button className="dwcButton" onClick={() => handleDownloadButton('afternoon')}>
                            Afternoon Session
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>    
        );
      }

}

export const objectFromQuery = (url?: string): {[key: string]: string} => {
    const regex = /[#&?]([^=]+)=([^#&?]+)/g;
    url = url || window.location.href;
    const object: { [param: string]: string } = {};
    let matches;
    // regex.exec returns new matches on each
    // call when we use /g like above
    while ((matches = regex.exec(url)) !== null) {
      object[matches[1]] = decodeURIComponent(matches[2]);
    }
    return object;
};